export const APP_ROUTE = {
  AUTH: 'auth',
  USER: 'user',
};

export const AUTH_ROUTE = {
  LOGIN: 'login',
  REGISTER_ACCOUNT: 'acceptinvite',
};

export const USER_ROUTE = {
  DASHBOARD: 'dashboard',
  PRODUCTS: 'products',
  PRODUCT_STATUS: 'product-status',
  PRODUCT_TEMPLATE: 'product-template',
  CUSTOMERS: 'customers',
  TIME_MANAGEMENT: 'time-management',
  OVERTIME_TEMPLATES: 'overtime-templates',
  OVERVIEW: 'overview',
  OVERVIEW_PRODUCT: 'overview-product',
  USERS_OVERVIEW: 'users-overview',
  USERS_PERMISSON: 'users-permission',
  USERS_TIME_SUMMARY: 'users-time-summary',
  REPORTS: 'reports',
  ERROR_404: '404',
  BIOGAS: 'biogas',
  ADDRESSBIOGAS: 'address',
  GLOBAL_ANALYSIS: 'global-analysis',
  VEHICLE_WEIGHTS: 'vehicle/weights',
  ASSIGNMENT_TASK_MANAGEMENT: 'assignment-task-management',
  BIOGAS_REGISTRATIONS: 'biogas-registrations',
};
